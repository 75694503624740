import React from "react"
import { Link } from "gatsby"
import ImageMeta from "../../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

// Only is set up for 2 or 5 docs
function MeetOurDoctors(props) {
  const data = props.post.meetOurDoctors
  const docLength = data.images.length
  const esDocClass = props.language === "es" ? "doc-info-es" : ""

  return (
    <section className={`body-section doc-info color-back ${esDocClass}`}>
      <div className="columns">
        <div className="column is-4"></div>
        <div
          className="column has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(converter.makeHtml(data.text))}
        ></div>
        <div className="column is-4"></div>
      </div>
      <div className={`columns our-docs is-multiline has-${docLength}-docs`}>
        <div className="column is-2"></div>
        <div className="our-docs__list">
          {data.images.map((image, i) => (
            <div key={image.doctor.url} className="our-docs__card">
              <Link to={image.doctor.url}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={image.doctor.imageId}
                  width="auto"
                  responsive
                  className="meet-our-doctor"
                ></ImageMeta>
              </Link>
              <Link to={image.doctor.url}>
                <h5 className="image-caption doctor">{image.doctor.caption}</h5>
              </Link>
            </div>
          ))}
        </div>
        <div className="column is-2"></div>
      </div>
    </section>
  )
}

export default MeetOurDoctors
